"use strict";
import CookieConsentForm from "./cookie-consent";

const texts = {
  h1: {
    de: 'Cookie-Einstellungen',
    en: 'Cookie settings'
  },
  '#cookie-consent-details-button' : {
    de: 'Details',
    en: 'details'
  },
  '#cookie-consent-links' : {
    de: '<a href="/flight13/datenschutz">Datenschutz</a> | <a href="/flight13/impressum">Impressum</a>',
    en: '<a href="/flight13/datenschutz">Data privacy</a> | <a href="/flight13/impressum">Imprint</a>'
  },
  p: {
    de: 'Wir verwenden Cookies um Inhalte zu personalisieren, Funktionen für soziale Medien anzubieten, sowie die Zugriffe auf unserer Website zu analysieren. Ihr könnt selbst entscheiden welche Funktionen zugelassen werden sollen. Eure Einstellungen könnt ihr jederzeit über den Link <i>Cookie-Einstellungen</i> im Fuß unserer Website ändern.',
    en: 'We use cookies to personalize content, to provide access to social media and to analyse access to our website. You can decide which options will be available. You can update your settings by clicking <i>Cookie settings</i> in the footer.'
  },
  '[value="allow_selection"]': {
    de: 'Auswahl speichern',
    en: 'Save selection'
  },
  '[value="allow_all"]': {
    de: 'Alle Cookies zulassen',
    en: 'Allow all cookies'
  },
  '#cookie-consent-details table thead' : {
    de: '<th>Kategorie</th><th>Beschreibung</th>',
    en: '<th>Category</th><th>Description</th>'
  },
};
const categories = {
  necessary: {
    mandatory: true,
    label:  {
      de: 'Notwendig',
      en: 'Necessary'
    },
    title: {
      de: 'Diese Cookies sind notwendig für die Funktion der Website.',
      en: 'These cookies are necessary for our website.'
    },
    text: {
      de: 'Diese Cookies sind notwendig für unsere Website. Wir verwenden ein Sitzungs-Cookie, einen Cookie für die Newsletter-Anmeldung, einen für die Cookie-Einstellungen, sowie die Analyse-Plattform Matomo zur kontinuierlichen Verbesserung unserer Dienste (Deine Daten werden nicht an Dritte weitergegeben). Für Kreditkartenzahlungen verwenden wir den Zahlungsdienstleister Stripe.',
      en: 'These cookies are necessary for our website. We use a session cookie, one for the newsletter promotion, one for the cookie settings and also cookies for the analytics platform Matomo for continual improvment of our services (Your data will not be send to third parties). We use the payment provider Stripe for credit card payments.'
    }
  },
  "meta-pixel": {
    mandatory: false,
    label:  {
      de: 'Marketing',
      en: 'Marketing'
    },
    title: {
      de: 'Facebook Pixel von Meta.',
      en: 'Facebook Pixel by Meta.'
    },
    text: {
      de: 'Wir verwenden das Facebook Pixel von Meta um dir personalisierte Werbung anbieten zu können.',
      en: 'We use the Facebook Pixel from Meta to offer you personalized advertising.'
    },
  },
  audio: {
    mandatory: false,
    label:  {
      de: 'Audio',
      en: 'Audio'
    },
    title: {
      de: 'Wir verwenden den Audio-Dienst von Phononet.',
      en: 'We use the audio service by phononet.'
    },
    text: {
      de: 'Wir verwenden den Audio-Dienst von Phononet. Sobald du Audio-Dateien abspielst nimmt dein Browser eine Verbindung zum Phononet Dienst auf.',
      en: 'We use the audio service by phononet. Your browser connects to the phononet service if you choose to play audio files.'
    },
    iframeText: {
      de: {
	h1: "Audio-Player",
	p: "Bitte stimme zur Nutzung der Datenübertragung an Phononet gemäß unserer Datenschutzerklärung zu.",
	".cookie-consent-allow-once": "Jetzt akzeptieren",
	".cookie-consent-allow-always": "Immer akzeptieren"
      },
      en: {
	h1: "Audio player",
	p: "Please agree to the use of Phononet services according to our privacy policy.",
	".cookie-consent-allow-once": "Accept now",
	".cookie-consent-allow-always": "Accept always"
      }
    }
  },
  video: {
    mandatory: false,
    label:  {
      de: 'Video',
      en: 'Video'
    },
    title: {
      de: 'Wir binden Youtube-Videos auf unseren Seiten ein.',
      en: 'We use Youtube videos on our pages.'
    },
    text: {
      de: 'Wir binden Youtube-Videos auf unseren Seiten ein. Sobald du ein Video abspielst werden Daten an den Dienst übertragen.',
      en: 'We use Youtube videos on our pages. Your browser connects to the Youtube service if you choose to play videos.'
    },
    iframeText: {
      de: {
	h1: "Youtube-Video",
	p: "Bitte stimme zur Nutzung der Datenübertragung an Youtube gemäß unserer Datenschutzerklärung zu.",
	".cookie-consent-allow-once": "Jetzt akzeptieren",
	".cookie-consent-allow-always": "Immer akzeptieren"
      },
      en: {
	h1: "Youtube video",
	p: "Please agree to the use of Youtube services according to our privacy policy.",
	".cookie-consent-allow-once": "Accept now",
	".cookie-consent-allow-always": "Accept always"
      }
    }
  },
};

function init() {
  let cookieConsentForm = new CookieConsentForm(
    document.getElementById('cookie-consent-popup'),
    {
      cookie_name: "cookie_consent",
      texts: texts,
      categories: categories,
      cookie_attrs: {
	"Max-Age" : 60 * 60 * 24 * 365 * 5, // 5 years
	"SameSite" : "Strict",
	"Path" : "/",
      },
      hide_on_pages: ['/flight13/impressum',
		      '/flight13/datenschutz'],
      html_template: '<div id="cookie-consent-inner"><form><h1></h1><p></p><div id="cookie-consent-checkboxes"></div><div id="cookie-consent-buttons" class="float-start"><button type="submit" value="allow_selection" class="btn btn-primary"></button> <button type="submit" value="allow_all" class="btn btn-success"></button></div> <div id="cookie-consent-details-div" class="float-end"><a href="#" id="cookie-consent-details-button" class="btn"></a></div><div class="clearfix"></div><div id="cookie-consent-details"><table class="table"><thead></thead><tbody></tbody></table></div><div id="cookie-consent-links"></div></form></div><div id="cookie-consent-backdrop"></div>'
    }
  );
  return cookieConsentForm;
};

export default init;
