let modal = null;

function init() {
  modal = new bootstrap.Modal("#newsletter-promo", { backdrop: true })
  document.addEventListener('cookie-consent-necessary', popup, false);
};

function popup() {
  if (!is_active()) return;

  document.removeEventListener('cookie-consent-necessary', popup, false);

  document.querySelector('#newsletter-promo .link-close').addEventListener("click", (ev) => {
    ev.preventDefault();
    deactivate();
    return false;
  });

  document.querySelector('#newsletter-promo .btn-success').addEventListener("click", (_ev) => {
    deactivate();
  });

  setTimeout(() => { modal.show() }, 15 * 1000);
}

function deactivate() {
  modal.hide();
  var d = new Date();
  var expiresInDays = 180 * 24 * 60 * 60 * 1000;
  d.setTime(d.getTime() + expiresInDays);
  var expires = "expires=" + d.toGMTString();
  document.cookie = "NEWSLETTER_PROMO=false;SameSite=Lax;" + expires + ";path=/";
}

function is_active() {
  if (!NEWSLETTER_PROMO) return false;

  return getCookie("NEWSLETTER_PROMO") !== "false";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

module.exports = { init }
