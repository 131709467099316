function init() {
  const collapseElementList = document.querySelectorAll('[data-bs-toggle="collapse"]');
  [...collapseElementList].map(
    element => {
      if (element.type != "checkbox") return;

      const target = document.querySelector(element.dataset.bsTarget);
      element.checked = target.classList.contains("show");
    }
  )

  const accordions = document.querySelectorAll('.accordion-keep-open');
  accordions.forEach(
    accordion => {
      accordion.addEventListener("hide.bs.collapse", (event) => {
	// enable current tab on accordion hide
	event.target
	  .closest(".accordion-item")
	  .querySelector(".accordion-header")
	  .classList.remove("pe-none");
      });
      accordion.addEventListener("show.bs.collapse", (event) => {
	// disable current tab on accordion show
	event.target
	  .closest(".accordion-item")
	  .querySelector(".accordion-header")
	  .classList.add("pe-none");
      });
    }
  )
}

module.exports = { init }
