//
// Cookie Consent
//
// tuxwerk OHG, 2020
//

"use strict";

// polyfills
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/prepend()/prepend().md
(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('prepend')) {
      return;
    }
    Object.defineProperty(item, 'prepend', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function prepend() {
        var argArr = Array.prototype.slice.call(arguments),
          docFrag = document.createDocumentFragment();

        argArr.forEach(function (argItem) {
          var isNode = argItem instanceof Node;
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });

        this.insertBefore(docFrag, this.firstChild);
      }
    });
  });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);

// cookie will be saved as
//
//  #{cookieName}=#{JSONized Values-Object}
//  where values-object is {category1: true/false, category2: true/false,...}
//
function ConsentCookie(cookie_name, categories, attrs, change_callback) {
  this.cookie_name = cookie_name || "consent_cookie";
  this.categories = categories;
  this.cookie_attrs = attrs || {};
  this.change_callback = change_callback
}

ConsentCookie.prototype = {
  allowedCategory: function(category) {
    var categories = this.allCategories();
    if (!categories) return false;

    return categories[category];
  },
  allowCategory: function(category) {
    var categories = this.allCategories();
    if (!categories) return false;

    categories[category] = true;
    this.setCookieVals(categories);
  },
  allCategoriesIncluded: function(categories) {
    for(var i = 0; i < this.categories.length; i++) {
      if (!categories.hasOwnProperty(this.categories[i]))
        return false;
    }
    return true;
  },
  allCategories: function() {
    var values = this.readCookie();
    if (!values) return null;

    if (this.allCategoriesIncluded(values)) {
      return values;
    } else {
      return null;
    }
  },
  readCookie: function() {
    var b = document.cookie.match('(^|;)\\s*' + this.cookie_name + '\\s*=\\s*([^;]+)');
    return b ? JSON.parse(b.pop()) : null;
  },
  writeCookie: function(categories) {
    var attrs = "";
    if (this.cookie_attrs['Max-Age']) {
      attrs += "Max-Age=" + this.cookie_attrs['Max-Age'] + ";";
    }
    if (this.cookie_attrs['SameSite']) {
      attrs += "SameSite=" + this.cookie_attrs['SameSite'] + ";";
    }
    if (this.cookie_attrs['Path']) {
      attrs += "Path=" + this.cookie_attrs['Path'] + ";";
    }
    document.cookie = this.cookie_name + "=" + JSON.stringify(categories) + ";" + attrs;
  },
  setCookieVals: function(categories) {
    var old     = this.allCategories();
    var changed = false;
    if (old) {
      for (var i = 0; i < Object.keys(categories).length; i++) {
        if (categories[Object.keys(categories)[i]] !==
	    old[Object.keys(categories)[i]]) {
          changed = true;
        }
      }
    } else {
      changed = true;
    }

    if (changed) {
      this.writeCookie(categories);
      this.change_callback();
    }
  }
}

function CookieConsentForm(container, config) {
  this.container = container;
  this.hide_on_pages = config.hide_on_pages || [];
  this.locale = config.locale || document.querySelector('html').lang;
  this.texts = config.texts;
  this.categories = config.categories;
  this.container.innerHTML = config.html_template || '<form><h1></h1><p></p><div class="cookie_consent_checkboxes"></div><button type="submit" value="allow_selection"></button> <button type="submit" value="allow_all"></button> <a href="" class="cookie_consent_details_button"></a><table class="cookie_consent_details"><thead /></table></form>';

  this.configuration_changed = (function() {
    this.enableScripts();
    this.maskIframesAndDivs();
    //location.reload(); // FIXME: should we really reload?
  }).bind(this);

  this.consentCookie = new ConsentCookie(config.cookie_name,
					 Object.keys(this.categories),
					 config.cookie_attrs,
					 this.configuration_changed);
  this.fillText();

  if (!this.consentCookie.allCategories()) {
    this.showDialog();
  } else {
    this.configuration_changed();
  }

  var that = this;

  this.container.querySelector('#cookie-consent-details').hidden = true;
  this.container.querySelector('#cookie-consent-details-button').addEventListener('click', function(el) {
    el.preventDefault();
    var details = that.container.querySelector('#cookie-consent-details');
    details.hidden = !details.hidden;
  });

  this.container.querySelectorAll("button").forEach(function(el) {
    el.addEventListener('click', function(el) {
      el.preventDefault();
      that.hideDialog();
      var categories = {};
      Object.keys(that.categories).forEach(function(category) {
	categories[category] =
	  el.target.value == 'allow_all' || that.selectedCategory(category);
      });
      var before = that.consentCookie.allCategories();
      that.consentCookie.setCookieVals(categories);
      if (!that._deepEqual(before, categories))
	location.reload();
    });
  });
}

CookieConsentForm.prototype = {
  selectedCategory: function(category) {
    return this.categories[category].hidden
      ?
      false
      :
      this.container.querySelector('[name="' + category + '"]').checked;
  },
  hideDialog: function() {
    this.container.style.display = 'none';
  },
  showDialog: function(force) {
    if (!force && !this.showOnPage()) return;

    this.makeCheckboxes();
    this.container.style.display = 'block';
  },
  showOnPage: function() {
    for (var i = 0; i < this.hide_on_pages.length; i++) {
      if (window.location.pathname === this.hide_on_pages[i]) {
	return false;
      }
    }
    return true;
  },
  categoryText: function(category, type) {
    try {
      return this.categories[category][type][this.locale];
    }
    catch (e) {
      return "";
    }
  },
  fillText: function() {
    var keys = Object.keys(this.texts);
    for (var i = 0; i < keys.length; i++) {
      this.container.querySelector(keys[i]).innerHTML =
	this.texts[keys[i]][this.locale];
    }
    var table = this.container.querySelector('#cookie-consent-details table tbody');
    var categories = Object.keys(this.categories);
    for (var i = 0; i < categories.length; i++) {
      if (this.categories[categories[i]].hidden) continue;

      table.innerHTML += '<tr><td>' + this.categoryText(categories[i], "label") + '</td><td>' + this.categoryText(categories[i], "text") + '<br />' + this.categoryText(categories[i], "details") + '</td>';
    }
  },
  makeCheckboxes: function() {
    var element = this.container.querySelector('#cookie-consent-checkboxes');
    var html = "";

    for (var i = 0; i < Object.keys(this.categories).length; i++) {
      var name = Object.keys(this.categories)[i];
      var conf = this.categories[name];
      if (conf.hidden) continue;

      var checked = conf.mandatory || this.consentCookie.allowedCategory(name)

      html += '<label title="' + conf.title[this.locale] + '">';
      html += '<input type="checkbox" ' + (checked ? 'checked' : '') + ' name="' + name + '" '+ (conf.mandatory ? 'disabled' : '') +  ' >';
      html += '<span>' + conf.label[this.locale] + '</span></label>';
    }
    element.innerHTML = html;
  },
  enableScripts: function() {
    var cvals = this.consentCookie.allCategories();

    this.consentCookie.categories.forEach(function(key) {
      if (!cvals[key]) return;

      document.querySelectorAll('[data-consent="' + key + '"]').forEach(function(element) {
        if (element.getAttribute("data-src")) {
          element.src = element.getAttribute("data-src");
        } else if (element.tagName === 'SCRIPT') {
	  var script = document.createElement('script');
	  script.setAttribute("type", "application/javascript");
	  script.innerHTML = element.innerHTML;
	  document.body.appendChild(script);
        } else {
	  // remove the masks
	  element.querySelectorAll('div.cookie-consent-divmask').forEach(function(element) {
	    element.parentNode.removeChild(element);
	  });
	}
      });
      // fire the consent event
      var event = document.createEvent("CustomEvent");
      event.initEvent('cookie-consent-' + key, true, false);
      document.dispatchEvent(event);
    });
  },
  maskIframesAndDivs: function() {
    var category;
    document.querySelectorAll('iframe[data-consent]').forEach(function(element) {
      category = element.getAttribute("data-consent");
      if (this.consentCookie.allowedCategory(category)) return;

      this.addIframeMask(
	element,
	this.categories[category].iframeStyle,
	this.categories[category].iframeText[this.locale]
      );
    }, this);
    document.querySelectorAll('div[data-consent]').forEach(function(element) {
      category = element.getAttribute("data-consent");
      if (this.consentCookie.allowedCategory(category)) return;

      this.addDivMask(
	element,
	this.categories[category].iframeText[this.locale]
      );
    }, this);
  },
  addIframeMask: function(element, style, text) {
    element.contentDocument.body.innerHTML = "<h1>Im Iframe</h1><p></p><button id='allow_once' onclick=\"window.parent.postMessage('allowElement','*');\">Element anzeigen</button><button id='allow_all' onclick=\"window.parent.postMessage('allowCategory', '*');\">Alle erlauben</button>";
    var styleLink = element.contentDocument.createElement("link");
    styleLink.rel = "stylesheet";
    styleLink.href = "data:text/css," + encodeURIComponent(style);
    element.contentDocument.body.appendChild(styleLink);
    var that = this;

    var texts = Object.keys(text);
    for (var k = 0; k < texts.length; k++) {
      element.contentDocument.body.querySelector(texts[k]).innerHTML = text[texts[k]];
    }
    window.addEventListener('message', function(event) {
      switch(event.data) {
      case 'allowElement':
	event.source.frameElement.src = event.source.frameElement.getAttribute("data-src");
	break;
      case 'allowCategory':
	that.consentCookie.allowCategory(element.getAttribute("data-consent"));
      }
    });
  },
  addDivMask: function(element, text) {
    var mask = document.createElement("div");
    mask.className = 'cookie-consent-divmask';
    mask.innerHTML = "<h1></h1><p></p><button class='cookie-consent-allow-once btn btn-primary btn-xs'></button> <button class='cookie-consent-allow-always btn btn-success btn-xs'></button>";
    var that = this;

    var texts = Object.keys(text);
    for (var k = 0; k < texts.length; k++) {
      mask.querySelector(texts[k]).innerHTML = text[texts[k]];
    }
    mask.querySelector('.cookie-consent-allow-once').addEventListener('click', function(event) {
      event.preventDefault();
      mask.style.display = 'none';
    });
    mask.querySelector('.cookie-consent-allow-always').addEventListener('click', function(event) {
      event.preventDefault();
      mask.style.display = 'none';
      that.consentCookie.allowCategory(element.getAttribute("data-consent"));
    });
    element.prepend(mask);
  },
  _deepEqual: function(x, y) {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
      ok(x).length === ok(y).length &&
	ok(x).every(key => this._deepEqual(x[key], y[key]))
    ) : (x === y);
  }
};

export default CookieConsentForm;
