let stripe = false;
let stripe_card = false;
let stripe_form = false;

function init(stripe_key, callback) {
  stripe_form = document.querySelector("#content > article form");
  callback(stripe_key);
}

function new_card(stripe_key) {
  let button    = document.getElementById("card-enter-new");
  let cancel    = document.getElementById("card-enter-cancel");
  if (!cancel) return;

  cancel.addEventListener("click", function(ev) {
    ev.preventDefault();
    _stripe_hide_card();
  });

  button.addEventListener("click", function(ev) {
    ev.preventDefault();
    _load_stripe(stripe_key, _stripe_show_card);
  });
}

function _load_stripe(stripe_key, callback) {
  if (stripe) {
    callback();
    return;
  }
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://js.stripe.com/v3/";
  script.onload = function() {
    stripe = Stripe(stripe_key);
    callback();
  }
  document.body.appendChild(script);
}

function _stripe_hide_card() {
  var error = document.getElementById("stripe-card-errors");
  error.textContent = "";
  document.getElementById("stripe-card-form").innerHTML = "";
  document.getElementById("stripe-card-form").className = "";
  stripe_form.removeEventListener("submit", _stripe_submit_new_card);
  document.getElementById("card-enter-cancel").classList.add("d-none");
  document.getElementById("card-enter-new").classList.remove("d-none");
}

function _stripe_show_card() {
  document.getElementById("card-enter-cancel").classList.remove("d-none");
  document.getElementById("card-enter-new").classList.add("d-none");
  var elements = stripe.elements({ "locale": document.querySelector("body").dataset.lang });
  stripe_card = elements.create("card",{ "hidePostalCode": true });
  stripe_card.mount("#stripe-card-form");

  stripe_card.on("change", function(event) {
    var error = document.getElementById("stripe-card-errors");
    if (event.error) {
      error.textContent = event.error.message;
    } else {
      error.textContent = "";
    }
  });

  stripe_form.addEventListener("submit", _stripe_submit_new_card);
}

function _stripe_submit_new_card(event) {
  if (!stripe_form.elements.kunde_zahlungsart_kreditkarte.checked)
    return;

  event.preventDefault();
  stripe.createSource(stripe_card, {"type": "card"} ).then(function(result) {
    if (result.error) {
      if (result.error)
        document.getElementById("stripe-card-errors").innerHTML = result.error.code + ": " + result.error.message;
      return;
    }
    if (result.source) {
      var hidden = document.createElement("input");
      hidden.setAttribute("type", "hidden");
      hidden.setAttribute("name", "stripe[source_id]");
      hidden.setAttribute("value", result.source.id);
      stripe_form.appendChild(hidden);
      stripe_form.submit();
    };
  });
}

module.exports = { init, new_card }
